import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import config from '../config';
import Cookies from 'js-cookie';
// import 'react-date-picker/dist/DatePicker.css';
// import 'react-calendar/dist/Calendar.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCategoryAction, createNftAction, createMetadataAction, getNftTypeAction, createKYC, getProfileAction } from '../Action/user.action';
const KYC = () => {

    const loginData = (!Cookies.get('loginSuccessScarlettUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessScarlettUserpanel'));
    const { id } = useParams();
    const [Category, setCategory] = useState([]);
    const [nftType, setnftType] = useState([]);
    const [image_preview, setimage_preview] = useState('');
    const [video_preview, setvideo_preview] = useState('');
    const [image_file, setimage_file] = useState('');
    const [FileType, setFileType] = useState('');
    const [validatioError, setvalidatioError] = useState({});
    const [spinloader, setspinloader] = useState(0);
    const [expiryDate, setcurrentDate] = useState(new Date());
    const [userDetails, setuserDetails] = useState({});

    const [kycdata, setnftdata] = useState({
        name: '',
        cardNo: '',
        expiryDate: null,
    });

    useEffect(() => {
        if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        }
        getProfileAPI();
        getCategoryAPI();
        getNftTypeAPI();
    }, []);
    const getCategoryAPI = async () => {
        let res = await getCategoryAction();
        if (res.success) {
            setCategory(res.data)
        }
    }
    const getProfileAPI = async () => {
        let res = await getProfileAction({ 'user_id': loginData?.id, 'email': loginData?.email });
        if (res.success) {
            setuserDetails(res.data)
        }
    }
    const getNftTypeAPI = async () => {
        let res = await getNftTypeAction();
        if (res.success) {
            setnftType(res.data)
        }
    }
    const imageUpload = async (e) => {
        e.preventDefault()
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
        let imageType = e.target.files[0].type;
        if (imageType == 'image/jpeg' || imageType == 'image/jpg' || imageType == 'image/png' || imageType == 'image/gif' || imageType == 'video/mp4') {
            let file_type = '';
            if (image_as_files.type.indexOf('image') === 0) {
                file_type = 'image';
                setimage_preview(image_as_base64);
            } else if (image_as_files.type.indexOf('video') === 0) {
                file_type = 'video';
                setvideo_preview(image_as_base64);
            }
            setimage_file(image_as_files);
            setFileType(file_type);
            setvalidatioError((old) => {
                return { ...old, ['imageError']: '' }
            })
        } else {
            toast.error('File type wrong please select JPG, JPEG, PNG or gif.');
        }
    }

    const inputHandler = (e) => {
        const { name, value, id } = e.target
        setnftdata({ ...kycdata, [name]: value })
        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }
    }

    function formatDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const handleChangeExpiryDate = e => {
        let expiryDate = formatDate(e);
        setnftdata({ ...kycdata, ['expiryDate']: expiryDate });
        setvalidatioError({ ...validatioError, ['startDateError']: '' });
    }

    const createNFt = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('data', JSON.stringify(kycdata));
            formData.append('document', image_file);
            await createKYC(formData);
            getProfileAPI();
        } catch(e) {}
    }

    return (
        <div className='create-item'>
            <Toaster />
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Know Your Customer</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="tf-create-item tf-section">
                <div className="themesflat-container">
                    {
                        userDetails?.kyc_status === 'SUBMITTED' ? (
                            <div className="row">
                                <div className="col-xl-2 col-lg-6 col-md-6 col-12"> </div>
                                <div className="col-xl-8 col-lg-6 col-md-12 col-12 mt-4">
                                    <div className="page-title-heading mg-bt-12">
                                        <h1 className="heading text-center">Your KYC has been submitted and under review</h1>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-6 col-md-6 col-12"></div>

                            </div>
                        ) : (
                            <div className="row">
                                <div className="col-xl-2 col-lg-6 col-md-6 col-12"> </div>
                                <div className="col-xl-8 col-lg-6 col-md-12 col-12 mt-4">
                                    <div className="form-create-item">
                                        <div className='add-category'>
                                            <h4 className="title-create-item">Upload ID</h4>
                                        </div>
                                        <label className="uploadFile upload">

                                            <>
                                                {
                                                    image_preview ?
                                                        <img src={image_preview} style={{ height: '115px', width: '115px' }} alt="" className="btn-rounded" /> :
                                                        video_preview ?
                                                            <video autoplay="true" muted loop id="myVideo" className='' style={{ height: '115px', width: '115px' }}>
                                                                <source src={video_preview} style={{ height: '115px', width: '115px' }} type="video/mp4" />
                                                            </video>
                                                            :
                                                            ''
                                                }
                                            </>
                                            <span className="filename">PNG, JPG, JPEG, GIF or MP4</span>
                                            <input onChange={imageUpload} type="file" className="inputfile form-control" name="file" />
                                        </label>
                                        <span className="validationErr">{validatioError.imageError}</span>
                                        <div className="flat-tabs tab-create-item">
                                            <Tabs>
                                                <TabPanel>
                                                    <form >
                                                        <h4 className="title-create-item">Full Name</h4>
                                                        <input type="text" placeholder="Enter Full Name" id='titleError' name='name' onChange={inputHandler} />
                                                        <span className="validationErr">{validatioError.nameError}</span>
                                                        <h4 className="title-create-item">Identity Card No.</h4>
                                                        <input type="text" placeholder="Enter Card No" id='cardNoError' name='cardNo' onChange={inputHandler} />
                                                        <span className="validationErr">{validatioError.cardNoError}</span>
                                                        <div className="inner-row-form">
                                                            <h4 className="title-create-item">Start Date</h4>
                                                            <span className="validationErr">{validatioError.expiryDateError}</span>
                                                            <DatePicker onChange={handleChangeExpiryDate} minDate={expiryDate} autoComplete="off" name="expiryDate" id="expiryDate" value={kycdata.expiryDate} />
                                                        </div>
                                                
                                                    </form>
                                                    <br/>
                                                    {
                                                        spinloader == '0' ?
                                                            <button className='btn btn-primary btn-lg' onClick={createNFt}>Submit</button>
                                                            :
                                                            <button disabled className='btn btn-primary btn-lg' >Creating NFT <i className="fa fa-spinner fa-spin validat"></i></button>
                                                    }
                                                </TabPanel>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-6 col-md-6 col-12"></div>
                            </div>
                        )
                    }
                    
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default KYC;
