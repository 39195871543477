import React, { useEffect, useState } from "react";
import piechart from "../assets/images/team/pie-chart-golden.png";
import ImageDropdown from "../components/layouts/ImageDropdown";
import { Link } from "react-router-dom";
import { useAccount, useContractRead } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import config from "../config";

const Settoken = () => {
  const options = [
    { image: "/images/ustd.png", value: "USDT" }
  ];
  const ltlOptions = [
    { image: "/images/ustd.png", value: "LTL" }
  ];
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const { isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const { data, error, isLoading, } = useContractRead({ address: config.icoAddress, abi: config.icoAbi, functionName: 'getPrice' });
  const [price, setPrice] = useState(0);
  const [ltlReceive, setLtlReceive] = useState(0);
  const [saleStatus, setSaleStatus] = useState(null);

  useEffect(() => {
    if(data !== undefined) {
      setPrice(data[0].toString() / 1e18);
      setSaleStatus(data[1].toString());
    }
  })
  return (
    <>
      <div className="overlay"></div>
      <section id="token-distribution" className="tf-section">
        <div className="themesflat-container ">
          <div className="row">
            <div className="col-md-6 col-lg-6 pr-5">
              <div className="content-area mb-5">
                <h2 className="tf-title pb-20 text-left">The LTL Token</h2>
                <div className="em_bar_bg"></div>
                <br />
                <br />
                {/* <p className="mt-3 about-token" >Victus Token created the VT Token. It is scheduled to debut on LA Token and will be accessible to the open market for trading. We aim to turn the VT Token into the foundational cryptocurrency for many upcoming commercial projects.</p>
                                <p className="about-token" >VT Token holders will be able to take advantage of various benefits, such as:</p>
                                <ul>
                                    <li className='benefits'>
                                        •	Purchase of Gaming NFTs in the upcoming Meta Game ‘Ground of Warriors’ <a href="https://drnft.app" style={{ color: "#ffa11e" }} target="_blank">DrNFT.app</a>
                                    </li>
                                    <li className='benefits'>
                                        •	Purchase of plans in the Victus Token infrastructure, such as the Metaverse
                                    </li>
                                    <li className='benefits'>
                                        •	Purchase of services in our upcoming Dating and Social apps
                                    </li>
                                    <li className='benefits'>
                                        •	Apply the VT Token as currency in upcoming industrial partnerships
                                    </li>
                                    <li className='benefits'>
                                        •	VT token payment for membership fees to access the VT P2P platform
                                    </li>
                                    <li className='benefits'>
                                        •	And many more to come
                                    </li>
                                </ul>
                                <p className="about-token" >Our mission is to embed the VT Token into both internal and external industrial applications and see its value appreciate through rising demand and utility.</p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 text-center">
              <div className="bg-color text-center">
                <table className="table table-striped border" width="100%">
                  <tbody>
                    <tr>
                      <td
                        style={{ borderTop: "none" }}
                        className="token-detail"
                      >
                        Token :
                      </td>
                      <td
                        style={{ borderTop: "none" }}
                        className="token-detail"
                      >
                        {" "}
                        <strong className="grey-accent2">
                          LTL Token (VT)
                        </strong>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td className="token-detail">Total Supply :</td>
                      <td className="token-detail">
                        <strong className="grey-accent2">200,000,000</strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="token-detail">Circulating supply :</td>
                      <td className="token-detail">
                        <strong className="grey-accent2">50,000,000</strong>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td className="token-detail">Token Symbol :</td>
                      <td className="token-detail">
                        <strong className="grey-accent2">VT</strong>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td className="token-detail">Token Decimal :</td>
                      <td className="token-detail">
                        <strong className="grey-accent2">18</strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="token-detail">Contract Address :</td>
                      <td className="token-detail">
                        {" "}
                        <strong className="grey-accent2">
                          <a
                            className="text-white"
                            target="_blank"
                            style={{
                              lineHeight: "1.4",
                              wordBreak: "break-all",
                            }}
                            href="https://bscscan.com/address/0xc9dd2bD67FA9aDB93a182Ea4a41b2b43feb8eA90"
                          >
                            0xc9dd2bD67FA9aDB93a182Ea4a41b2b43feb8eA90
                          </a>
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 text-center">
              <div className="calculator">
                <div className="inner-calculator">
                <h4 className="pt-3 pb-5">LTL Price Calculator</h4>
                  <div className="d-flex group-input">
                    <input type="text" placeholder="0.0" onChange={(e) => {
                      e.preventDefault();
                      setLtlReceive(price * parseFloat(e.target.value || 0));
                    }} />
                    <ImageDropdown
                      style={{ cursor: "pointer" }}
                      onChangeListener={setSelectedOption}
                      options={options}
                    />
                  </div>
                  <div className="d-flex justify-content-center mt-4 mb-4">
                    
                  <div className="swap">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>
                  </div>
                  </div>
                  <div className="d-flex group-input">
                    <input readOnly type="text" placeholder="0.0" value={ltlReceive}/>
                    <ImageDropdown
                      style={{ cursor: "pointer" }}
                      onChangeListener={setSelectedOption}
                      options={ltlOptions}
                    />
                  </div>
                  <Link style={{ display: 'none' }} id="redirect-to-purchase" to={'/ico'} className="swap-btn w-100 mt-5 mb-4">Go to Purchase</Link>
                  { isConnected ? <button onClick={(e) => {
                    e.preventDefault();
                    document.getElementById('redirect-to-purchase').click();
                  }} className="swap-btn w-100 mt-5 mb-4">Go to Purchase</button> :  <button className="swap-btn w-100 mt-5 mb-4" onClick={(e) => {
                    e.preventDefault();
                    open();
                  }}>Connect Wallet</button>  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Settoken;
