import './App.css';
import {   Routes , Route } from 'react-router-dom';
import routes from './pages/index'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { goerli, mainnet, polygon, polygonMumbai, sepolia } from 'wagmi/chains';
import useNavigateToKyc from './components/hooks/useNavigateToKyc';
import ICO from './pages/ICO';

const chains = [goerli, sepolia, mainnet, polygon, polygonMumbai];
const projectId = process.env.REACT_APP_PROJECT_ID;

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
});

const ethereumClient = new EthereumClient(wagmiConfig, chains);

function App() {

    useNavigateToKyc();
    return (
      <div>
        <WagmiConfig config={wagmiConfig}>
        <Routes >
          {
            routes.map((data,index) => (
                <Route onUpdate={() => window.scrollTo(0, 0)} exact={true} path={data.path} element={data.component} key={index} />
                ))
              }
              {/* <Route element={ICO} path='/ico' /> */}
        </Routes>
      </WagmiConfig>
        <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      </div>
    );
}

export default App;
