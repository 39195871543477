import React, { useState } from 'react';
import Select from 'react-select';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    background: `url(${state.data.image}) no-repeat left center`,
    backgroundSize: '20px 20px',
    paddingLeft: '30px',
  }),
};

export default function ImageDropdown(props) {
  const { options, onChangeListener } = props;
  const defaultOption = options[0]; // Set the default option

  return (
      <Select
        defaultValue={defaultOption}
        onChange={onChangeListener}
        options={options}
        components={{
          Option: CustomOption,
          SingleValue: CustomSingleValue,
        }}
        styles={customStyles}
      />
  );
}

const CustomOption = ({ innerProps, label, data }) => (
  <div {...innerProps}>
    <img src={data.image} alt={label} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
    {label}
  </div>
);

const CustomSingleValue = ({ innerProps, label, data }) => (
  <div {...innerProps}>
    <img src={data.image} alt={label} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
    {label}
  </div>
);
