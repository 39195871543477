import { useEffect, useState } from "react"
import { getProfileAction } from "../../Action/user.action";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";

const useNavigateToKyc = () => {

    const loginData = (!Cookies.get('loginSuccessScarlettUserpanel')) ? [] : JSON.parse(Cookies.get('loginSuccessScarlettUserpanel'));
    const [userDetails, setuserDetails] = useState({});
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        if(loginData && !Array.isArray(loginData)) getProfileAPI();
    }, []);

    useEffect(() => {
        if(userDetails?.kyc_status && userDetails?.kyc_status !== 'APPROVED') navigate('/kyc');
        else if(userDetails?.kyc_status && pathname === '/kyc') navigate('/marketplace');
    }, [userDetails?.kyc_status, pathname]);

    const getProfileAPI = async () => {
        let res = await getProfileAction({ 'user_id': loginData?.id, 'email': loginData?.email });
        if (res.success) {
            setuserDetails(res.data)
        }
    }
        
}

export default useNavigateToKyc;