import React, { useState, useEffect } from "react";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  useNetwork,
  useWalletClient,
} from "wagmi";
import { parseEther, getContract } from "viem";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import ImageDropdown from "../components/layouts/ImageDropdown";
import StickyBox from "react-sticky-box";
import config from '../config';
import toast from "react-hot-toast";
import { useWeb3Modal } from "@web3modal/react";
import CookieConsent from "react-cookie-consent";
import Header from "../components/header/Header";
import Banner from "./Banner";
import millify from "millify";
import { getWalletClient, waitForTransaction } from '@wagmi/core';

const ICO = () => {
  const options = [
    { image: "/images/ustd.png", value: "USDT" },
    // { image: "/images/eth.png", value: "ETH" },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const [isLoading, setIsLoading] = useState(false);

  const [amount, setAmount] = useState(0);

  const { address } = useAccount();
  const { chain } = useNetwork();
  const { data: walletClient } = useWalletClient();
  const [maxSupply, setMaxSupply] = useState(0);
  const [remainingTokens, setRemainingTokens] = useState(0);
  const [allocation, setAllocation] = useState(0);
  const [launchDate, setLaunchDate] = useState("");
  const [launchingPlatform, setLaunchingPlatform] = useState("");
  const [remainingAmount, setRemainingAmount] = useState("");

  const { writeAsync }  = useContractWrite({ address: config.icoAddress, abi: config.icoAbi, functionName: 'buy'  });
  const usdtToken  = useContractWrite({ address: config.usdtAddress, abi: config.usdtAbi, functionName: 'approve'  });
  const marketingSaleDetail  = useContractRead({ address: config.icoAddress, abi: config.icoAbi, functionName: 'getMarketingSaleDetaile'  });
  const preSaleDetail  = useContractRead({ address: config.icoAddress, abi: config.icoAbi, functionName: 'getPreSaleDetaile'  });
  const privateSaleDetaile  = useContractRead({ address: config.icoAddress, abi: config.icoAbi, functionName: 'getPrivateSaleDetaile'  });
  const publicSaleDetaile  = useContractRead({ address: config.icoAddress, abi: config.icoAbi, functionName: 'getPublicSaleDetaile'  });
  const RDSaleDetaile  = useContractRead({ address: config.icoAddress, abi: config.icoAbi, functionName: 'getRDSaleDetaile'  });

  const { isConnected } = useAccount();
  const { open } = useWeb3Modal();

  return (
    <div>
      <CookieConsent
        buttonText="Accept"
        cookieName="cookieName"
        declineCookieValue={false}
        style={{ background: "linear-gradient(45deg, black, #d9a243)", boxShadow: "0px 0px 4px 0px #ffa11e" }}
        buttonStyle={{ background: "#f5f5f5", color: "black", fontSize: "13px", borderRadius: "10px", padding: "10px 10px " }}
        // declineButtonStyle={{background:"#cfcfcf", color: "black", fontSize: "13px"}}
        expires={150}> This LTL token site uses cookies.
      </CookieConsent>
      <Header />
      <div style={{ background: "#14141F", paddingTop:'100px' }}>
        {/* <ToastContainer /> */}

        <div className="container pt-5">
          <div className="row">
            <div className="col-xl-4 col-lg-5 col-md-5">
              <StickyBox offsetTop={27} offsetBottom={115}>
                <div className="card-eth">
                  <div className="d-flex align-items-center">
                    <img
                      style={{ width: "30%", marginRight: "20px" }}
                      src="/images/thumb.png"
                      alt="thumb"
                    />

                    <div className="d-flex align-items-center  justify-content-between">
                      <div>
                        <h3 className="tf-title " style={{fontSize:"18px"}}>LTL TOKEN</h3>
                        <h6>Symbol: LTL</h6>
                        <h6>Decimal: 18</h6>
                      </div>
                      <img
                        src="/images/eth-bgout.png"
                        alt="eth"
                        style={{ marginLeft: "30px" }}
                      />
                    </div>
                  </div>
                  <hr
                    style={{
                      color: "white",

                      marginTop: "30px",
                      marginBottom: "30px",
                      border: "1px solid ",
                    }}
                  />

                  <label>Amount in {selectedOption.value}</label>
                  <div
                    className="input-group flex-nowrap"
                    style={{ margin: " 0px 0px 20px", paddingRight: "10px" }}
                  >
                    <input
                      type="text"
                      className="form-control cus-input"
                      placeholder="Enter amount"
                      aria-describedby="addon-wrapping"
                      onChange={(e) => setAmount(e.target.value)}
                      style={{
                        height: "54px",
                        marginTop: "0px",
                        color: "white",
                      }}
                    />
                    <span className="input-group-text" id="addon-wrapping">
                      <ImageDropdown
                        options={options}
                        onChangeListener={setSelectedOption}
                      />
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pt-4">
                    {/* <button onClick={() => {
                    if(selectedOption.value === 'USDT') transferUsdt();
                    else transferEther();
                  }} className="purchase">Purchase</button> */}

                    <button onClick={async (e) => {
                      if(!isConnected) {
                        open();
                        return;
                      }
                      setIsLoading(true);
                      try {
                        const usdtHash = await usdtToken.writeAsync({ args: [config.icoAddress, amount * 1e18] });
                        await waitForTransaction({ hash: usdtHash.hash });
                        const { hash } = await writeAsync({ args: [amount * (1e18)] });
                        toast.success(`Purchased ${hash.slice(0, 5)}...${hash.slice(hash.length - 5, hash.length)}`);
                      } catch(e) {
                        toast.error(`${e.message}`);
                      }
                      setIsLoading(false);
                    }} className="purchase" disabled={isLoading}>
                      {isLoading ? 'Loading...' : "Purchase"}
                    </button>

                    <div className="d-flex align-items-center">
                      <a
                        href="https://twitter.com/xdaleproperties"
                        target="_blank"
                      >
                        <svg
                          width="27"
                          height="23"
                          style={{ marginRight: "15px" }}
                          viewBox="0 0 27 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M23.961 6.16867C23.9776 6.40074 23.9776 6.63287 23.9776 6.8652C23.9776 13.9428 18.5862 22.1021 8.73269 22.1021C5.6975 22.1021 2.87674 21.2234 0.507812 19.6982C0.93907 19.7479 1.35379 19.7645 1.80153 19.7645C4.30627 19.7645 6.61137 18.919 8.45393 17.4769C6.09879 17.4272 4.12502 15.8853 3.44498 13.7636C3.77673 13.8133 4.10817 13.8465 4.45688 13.8465C4.93786 13.8465 5.41874 13.7801 5.86649 13.6642C3.41179 13.1668 1.57077 11.0117 1.57077 8.45421V8.38792C2.284 8.78579 3.11312 9.03444 3.99228 9.06761C2.54948 8.10625 1.60395 6.46468 1.60395 4.60829C1.60395 3.61375 1.86928 2.70189 2.33352 1.90636C4.97048 5.15531 8.93486 7.2796 13.3817 7.50922C13.2988 7.11136 13.249 6.69686 13.249 6.28251C13.249 3.33157 15.6373 0.929688 18.6046 0.929688C20.147 0.929688 21.5403 1.57621 22.519 2.62075C23.73 2.38868 24.891 1.94106 25.9192 1.32771C25.5211 2.57125 24.675 3.61528 23.5641 4.27865C24.6423 4.16266 25.6869 3.8642 26.6493 3.44995C25.9197 4.51082 25.0074 5.45586 23.9623 6.21822L23.961 6.16867Z"
                            fill="#F9F9F9"
                          />
                        </svg>
                      </a>

                      <a
                        href="https://www.google.com/search?sca_esv=566267321&sxsrf=AM9HkKlrSvhMECEBWEyKMyWUkGx0tnKB2g:1695044216633&q=xdale&tbm=isch&source=lnms&sa=X&ved=2ahUKEwiKxaTjo7SBAxWDQaQEHf8xCcMQ0pQJegQIDRAB&biw=1792&bih=957&dpr=2#imgrc=4jdIWjRDjk0RYM"
                        target="_blank"
                      >
                        <svg
                          width="27"
                          style={{ marginRight: "15px" }}
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.4907 13.4909C18.4907 14.6237 18.4294 15.7157 18.3222 16.7567H8.85673C8.74951 15.7157 8.6423 14.6237 8.6423 13.4909C8.6423 12.3581 8.74951 11.2661 8.85673 10.2251H18.3222C18.4294 11.2661 18.4907 12.3581 18.4907 13.4909ZM26.2459 10.2251C26.5165 11.2712 26.6594 12.3632 26.6594 13.4909C26.6594 14.6186 26.5165 15.7106 26.2459 16.7567H19.9611C20.0683 15.7055 20.1245 14.5676 20.1245 13.4909C20.1245 12.3683 20.0683 11.2763 19.9611 10.2251H26.2459ZM25.7098 8.59219H19.7518C19.2412 5.33355 18.2303 2.60254 16.9284 0.858512C20.9311 1.91214 24.1782 4.81307 25.7098 8.59219ZM18.0976 8.59219H9.08137C9.3928 6.73478 9.87271 5.09066 10.4598 3.76241C10.9959 2.55764 11.5932 1.68353 12.1702 1.13243C12.742 0.589901 13.2168 0.427734 13.5895 0.427734C13.9622 0.427734 14.437 0.589901 15.0088 1.13243C15.5857 1.68353 16.183 2.55764 16.7191 3.76241C17.3062 5.09066 17.7862 6.73478 18.0976 8.59219ZM1.46966 8.59219C3.00027 4.81307 6.24784 1.91214 10.2505 0.858512C8.94863 2.60254 7.93775 5.33355 7.4272 8.59219H1.46966ZM7.21788 10.2251C7.11066 11.2763 7.00856 12.3683 7.00856 13.4909C7.00856 14.5676 7.11066 15.7055 7.21788 16.7567H0.931286C0.662484 15.7106 0.519531 14.6186 0.519531 13.4909C0.519531 12.3632 0.662484 11.2712 0.931286 10.2251H7.21788ZM10.4598 23.2168C9.87271 21.8901 9.3928 20.247 9.08137 18.3895H18.0976C17.7862 20.247 17.3062 21.8901 16.7191 23.2168C16.183 24.4261 15.5857 25.2987 15.0088 25.8498C14.437 26.3907 13.9622 26.554 13.5435 26.554C13.2168 26.554 12.742 26.3907 12.1702 25.8498C11.5932 25.2987 10.9959 24.4261 10.4598 23.2168ZM10.2505 26.1254C6.24784 25.0691 3.00027 22.1707 1.46966 18.3895H7.4272C7.93775 21.6502 8.94863 24.3802 10.2505 26.1254ZM16.9284 26.1254C18.2303 24.3802 19.2412 21.6502 19.7518 18.3895H25.7098C24.1782 22.1707 20.9311 25.0691 16.9284 26.1254Z"
                            fill="#F9F9F9"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </StickyBox>
            </div>

            <div className="col-xl-8 col-lg-7 col-md-7">
              <div className="project-summmary">
                <h3 className="tf-title ">Project Summary</h3>
                <p style={{ textAlign: "justify" }}>
                  LTL Token is at the heart of the BitBrowze NFT Marketplace,
                  empowering artists to showcase and monetize their creations in
                  a secure and decentralized environment. Within this innovative
                  ecosystem, artists mint their artworks as NFTs and price them
                  in LTL Tokens, while buyers acquire LTL Tokens to purchase
                  these unique pieces. Users are further incentivized with LTL
                  Token rewards for their purchases, ensuring active engagement.
                  The value of LTL Tokens remains stable, typically pegged to a
                  stablecoin like USDT, providing predictability in
                  transactions. This synergy between art and blockchain
                  technology fosters a thriving artistic community where artists
                  gain value for their creations, and art enthusiasts access a
                  world of unique, tokenized artworks.
                </p>
                {/* <p className="pt-4" style={{ textAlign: "justify" }}>
                torquent per conubia nostra, per inceptos himenaeos. Curabitur
                tempus urna at turpis condimentum lobortis. Ut commodo efficitur
                neque.turpis condimentum lobortis. Ut commodo efficitur neque
              </p> */}
              </div>
              {/* <div className="slider">
              <MyCarousel></MyCarousel>
            </div> */}
              <div className="project-summmary mt-4">
                <h3 className="tf-title ">Project Overview</h3>
                <p style={{ textAlign: "justify" }}>
                  LTL Token is a groundbreaking project aimed at revolutionizing
                  the art world within the BigBrose NFT Marketplace. This
                  marketplace serves as a platform for artists to showcase and
                  sell their unique artworks to a global audience. LTL Token, a
                  cryptocurrency specifically designed for this ecosystem, plays
                  a central role in enabling the seamless exchange of art and
                  value.
                </p>
                <h6 className="tf-title ">Project Overview</h6>
                <ul style={{color:"white"}}>
                  <li>
                    BigBrose is an NFT marketplace that provides artists with a
                    digital canvas to exhibit their artwork to potential buyers
                    worldwide. Artists can mint their artwork as NFTs
                    (Non-Fungible Tokens) and list them for sale.
                  </li>
                  <li>
                    LTL Token is the native cryptocurrency of the BigBrose
                    ecosystem. It serves as the primary medium of exchange
                    within the platform. Users can acquire LTL Tokens to
                    facilitate purchases of NFT artworks.
                  </li>
                  <li>
                    LTL Token empowers artists by allowing them to receive
                    payments in XER for their artwork. This provides artists
                    with an opportunity to gain value for their creativity in a
                    secure and decentralized manner.
                  </li>
                  <li>
                    Users who purchase NFT artworks with LTL Tokens are rewarded
                    with additional XER tokens. This incentivizes art
                    enthusiasts to engage in the platform actively.
                  </li>
                  <li>
                    LTL Token is designed to have a stable value, typically
                    pegged to a stablecoin like USDT (Tether). This ensures that
                    the value of artworks and transactions remains predictable
                    and reliable.
                  </li>
                </ul>
              </div>
              {/*  */}
              {
                isConnected ? <>
                <div className="row pb-4 pt-3">
                  <div className="col-lg-12">
                    <div className="network-main">
                      <div className="d-flex justify-content-between align-items-center"></div>
                      <div className="inner-network mt-3">
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                          <h3>Marketing Sale Detail</h3>
                          <br/>
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Allocation</p>
                                <h4>{marketingSaleDetail.data?.[0] !== undefined ? millify(marketingSaleDetail.data?.[0]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                              </div>
  
                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Remaining Amount</p>
                                <h4>{marketingSaleDetail.data?.[1] !== undefined ? millify(marketingSaleDetail.data?.[1]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                              </div>
                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Sale Per Token Price</p>
                                <h4>{marketingSaleDetail.data?.[4] !== undefined ? millify(marketingSaleDetail.data?.[2]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                              </div>
                            </div>
  
                            <hr
                              style={{
                                color: "white",
                                // height:'10px',
                                marginTop: "30px",
                                marginBottom: "30px",
                                border: "1px solid ",
                              }}
                            />
  
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-lg-4 col-md-4 col-4">
                                  <p>Vesting Percentage</p>
                                  <h4>{marketingSaleDetail.data?.[5] !== undefined ? millify(marketingSaleDetail.data?.[4]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                                </div>
                                <div className="col-lg-4 col-md-4 col-4">
                                  <p>Vesting Alloc. Amount</p>
                                  <div>
                                  <h4>{marketingSaleDetail.data?.[6] !== undefined ? millify(marketingSaleDetail.data?.[0]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pb-5 ">
                  <div className="col-lg-12">
                    <div className="network-main">
                      <div className="d-flex justify-content-between align-items-center"></div>
                      <div className="inner-network mt-3">
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                          <h3>Pre Sale Detail</h3>
                          <br/>
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Allocation</p>
                                <h4>{preSaleDetail.data?.[0] !== undefined ? millify(preSaleDetail.data?.[0]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                              </div>
  
                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Remaining Amount</p>
                                <h4>{preSaleDetail.data?.[1] !== undefined ? millify(preSaleDetail.data?.[1]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                              </div>
                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Sale Per Token Price</p>
                                <h4>{preSaleDetail.data?.[4] !== undefined ? millify(preSaleDetail.data?.[2]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                              </div>
                            </div>
  
                            <hr
                              style={{
                                color: "white",
                                // height:'10px',
                                marginTop: "30px",
                                marginBottom: "30px",
                                border: "1px solid ",
                              }}
                            />
  
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-lg-4 col-md-4 col-4">
                                  <p>Vesting Percentage</p>
                                  <h4>{preSaleDetail.data?.[5] !== undefined ? millify(preSaleDetail.data?.[4]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                                </div>
                                <div className="col-lg-4 col-md-4 col-4">
                                  <p>Vesting Alloc. Amount</p>
                                  <div>
                                  <h4>{preSaleDetail.data?.[6] !== undefined ? millify(preSaleDetail.data?.[0]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div className="row pb-5 ">
                  <div className="col-lg-12">
                    <div className="network-main">
                      <div className="d-flex justify-content-between align-items-center"></div>
                      <div className="inner-network mt-3">
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                          <h3>Private Sale Detail</h3>
                          <br/>
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Allocation</p>
                                <h4>{privateSaleDetaile.data?.[0] !== undefined ? millify(privateSaleDetaile.data?.[0]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                              </div>
  
                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Remaining Amount</p>
                                <h4>{privateSaleDetaile.data?.[1] !== undefined ? millify(privateSaleDetaile.data?.[1]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                              </div>
                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Sale Per Token Price</p>
                                <h4>{privateSaleDetaile.data?.[4] !== undefined ? millify(privateSaleDetaile.data?.[2]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                              </div>
                            </div>
  
                            <hr
                              style={{
                                color: "white",
                                // height:'10px',
                                marginTop: "30px",
                                marginBottom: "30px",
                                border: "1px solid ",
                              }}
                            />
  
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-lg-4 col-md-4 col-4">
                                  <p>Vesting Percentage</p>
                                  <h4>{privateSaleDetaile.data?.[5] !== undefined ? millify(privateSaleDetaile.data?.[4]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                                </div>
                                <div className="col-lg-4 col-md-4 col-4">
                                  <p>Vesting Alloc. Amount</p>
                                  <div>
                                  <h4>{privateSaleDetaile.data?.[6] !== undefined ? millify(privateSaleDetaile.data?.[0]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div className="row pb-5 ">
                  <div className="col-lg-12">
                    <div className="network-main">
                      <div className="d-flex justify-content-between align-items-center"></div>
                      <div className="inner-network mt-3">
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                          <h3>Public Sale Detail</h3>
                          <br/>
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Allocation</p>
                                <h4>{publicSaleDetaile.data?.[0] !== undefined ? millify(publicSaleDetaile.data?.[0]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                              </div>
  
                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Remaining Amount</p>
                                <h4>{publicSaleDetaile.data?.[1] !== undefined ? millify(publicSaleDetaile.data?.[1]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                              </div>
                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Sale Per Token Price</p>
                                <h4>{publicSaleDetaile.data?.[4] !== undefined ? millify(publicSaleDetaile.data?.[2]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                              </div>
                            </div>
  
                            <hr
                              style={{
                                color: "white",
                                // height:'10px',
                                marginTop: "30px",
                                marginBottom: "30px",
                                border: "1px solid ",
                              }}
                            />
  
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-lg-4 col-md-4 col-4">
                                  <p>Vesting Percentage</p>
                                  <h4>{publicSaleDetaile.data?.[5] !== undefined ? millify(publicSaleDetaile.data?.[4]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                                </div>
                                <div className="col-lg-4 col-md-4 col-4">
                                  <p>Vesting Alloc. Amount</p>
                                  <div>
                                  <h4>{publicSaleDetaile.data?.[6] !== undefined ? millify(publicSaleDetaile.data?.[0]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div className="row pb-5 ">
                  <div className="col-lg-12">
                    <div className="network-main">
                      <div className="d-flex justify-content-between align-items-center"></div>
                      <div className="inner-network mt-3">
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                          <h3>RD Sale Detail</h3>
                          <br/>
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Allocation</p>
                                <h4>{RDSaleDetaile.data?.[0] !== undefined ? millify(RDSaleDetaile.data?.[0]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                              </div>
  
                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Remaining Amount</p>
                                <h4>{RDSaleDetaile.data?.[1] !== undefined ? millify(RDSaleDetaile.data?.[1]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                              </div>
                              <div className="col-lg-4 col-md-4 col-4">
                                <p>Sale Per Token Price</p>
                                <h4>{RDSaleDetaile.data?.[4] !== undefined ? millify(RDSaleDetaile.data?.[2]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                              </div>
                            </div>
  
                            <hr
                              style={{
                                color: "white",
                                // height:'10px',
                                marginTop: "30px",
                                marginBottom: "30px",
                                border: "1px solid ",
                              }}
                            />
  
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-lg-4 col-md-4 col-4">
                                  <p>Vesting Percentage</p>
                                  <h4>{RDSaleDetaile.data?.[5] !== undefined ? millify(RDSaleDetaile.data?.[4]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                                </div>
                                <div className="col-lg-4 col-md-4 col-4">
                                  <p>Vesting Alloc. Amount</p>
                                  <div>
                                  <h4>{RDSaleDetaile.data?.[6] !== undefined ? millify(RDSaleDetaile.data?.[0]?.toString() / 10 ** 18, { precision: 2, lowercase: true
                                 }) : 'N/A'}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </> : (
                  <div className="row pb-5 ">
                  <div className="col-lg-12">
                    <div className="network-main">
                      <div className="d-flex justify-content-between align-items-center"></div>
                      <div className="inner-network mt-3">
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <h3 onClick={(e) => {
                              e.preventDefault();
                              open();
                            }} style={{ cursor: 'pointer' }}>Connect wallet to Get Insights</h3>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                    </div>
                )
              }
              
              
              {/*  */}
            </div>
            {/* <div className="contact">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      className="w-30"
                      src="/Images/mobile.png"
                      alt="mobile"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <h1>Contact for More Info</h1>
                  <p>
                    Get access to huge set of tools to seamlessly handle your
                    game's integration with Yorem ipsum dolor sit amet,
                  </p>
                  <button
                    className="contact-button"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <a
                      style={{ textDecoration: "none", color: "white" }}
                      href="mailto:support@example.com"
                    >
                      Contact Support
                    </a>
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ICO;
